:root{
  --body_background:rgb(255, 239, 239);
  --body_color:#13132d;
  --link_color:navy;
}

[data-theme="dark"]{
  --body_background:rgba(24,18,39,255);
  --body_color:rgb(255, 251, 251);
  --link_color:rgb(92, 92, 255);
}

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}